$(document).ready(function() {
  $('.hamburger').click(function() {
    $(this).toggleClass('active');
    $('.nav').toggleClass('active');
    $('body').toggleClass('hidden');
  });

  $('.nav__item').click(function() {
    if($(document).width() < 1199) {
      $('.nav, .hamburger').removeClass('active');
      $('body').removeClass('hidden');
    }
  })

  $(window).scroll(function() {
    var offerHeight = $('.offer').height();
    var advantagesHeight = $('.advantages').height();
    var rangeHeight = $('.range').height();
    var comparisonPos = $('.comparison').offset().top;
    var historyPos = $('.history').offset().top;
    var floorPos = $('.floor').offset().top;
    var contactsPos = $('.contacts').offset().top;
    var scroll = $(this).scrollTop();
    console.log(contactsPos);
    console.log(scroll);
    if(scroll > offerHeight / 2) {
      $('.offer').addClass('active');
    }

    if(scroll > advantagesHeight + 300) {
      $('.advantages').addClass('active');
    }

    if(scroll > rangeHeight + 1000) {
      $('.range').addClass('active');
    }

    if(scroll > comparisonPos) {
      $('.comparison').addClass('active');
    }

    if(scroll > historyPos - 100) {
      $('.history').addClass('active');
    }

    if(scroll > floorPos - 300) {
      $('.floor').addClass('active');
    }

    if(scroll > contactsPos - 400) {
      $('.contacts').addClass('active');
    }
  });

  $('.advantages__icon, .advantages__title').click(function() {
    $(this).closest('.advantages__item').toggleClass('active');
    if($(document).width() < 991) {
      $(this).closest('.advantages__item').find('.advantages__descr').toggleClass('active');
    }
  });

  $('.range__title').click(function() {
    $(this).closest('.range__item').find('.range__content').slideToggle();
    $(this).toggleClass('active');
    $('.product').slick('unslick');
    $('.product').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      dots: false,
      responsive: [{
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  });

  $('.product').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    responsive: [{
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $(document).ready(function() {
    var elem = $('body'),
      pos = elem.offset(),
      elem_left = pos.left,
      elem_top = pos.top,
      elem_width = elem.width(),
      elem_height = elem.height(),
      x_center,
      y_center;

    $('body').mousemove(function(e) {
      x_center = (elem_width / 2) - (e.pageX - elem_left);
      y_center = (elem_width / 2) - (e.pageY - elem_top);
      $('.parallax').each(function() {
        var speed = $(this).attr('data-speed'),
          xPos = Math.round(-1 * x_center / 20 * speed),
          yPos = Math.round(y_center / 20 * speed);
        if(yPos < 0)
          yPos = -2 * speed;
        $(this).css('transform', 'translate3d(' + xPos + 'px, ' + yPos + 'px, 0px)');
      });
    });
  });

  $('.btn--modal').click(function() {
    $('.modal, .overlay').fadeIn();
    return false;
  });

  $('.modal__close, .overlay').click(function() {
    $('.modal, .overlay').fadeOut();
  });

  $(this).keydown(function(eventObject) {
    if(eventObject.which == 27)
      $('.modal, .overlay').fadeOut();
  });

  $("a.scrollto").click(function() {
    var elementClick = $(this).attr("href")
    var destination = $(elementClick).offset().top;
    $("html:not(:animated),body:not(:animated)").animate({
      scrollTop: destination
    }, 800);
    return false;
  });

  $("a.scrolltohistory").click(function() {
    var elementClick = $(this).attr("href")
    var destination = $(elementClick).offset().top;
    $("html:not(:animated),body:not(:animated)").animate({
      scrollTop: destination + 250
    }, 800);
    return false;
  });

  $("a.scrolltocomparison").click(function() {
    var elementClick = $(this).attr("href")
    var destination = $(elementClick).offset().top;
    $("html:not(:animated),body:not(:animated)").animate({
      scrollTop: destination + 400
    }, 800);
    return false;
  });

});